module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TWX8HN9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change trigger","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-GKNJC78CTC","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-TWX8HN9","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Today's Amazon Deals and Discounts","short_name":"Amazon Deals","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{},"allPageHeaders":["Strict-Transport-Security: max-age=15768000; includeSubDomains; preload","X-Frame-Options: DENY","X-XSS-Protection: 1; mode=block","X-Content-Type-Options: nosniff","X-Permitted-Cross-Domain-Policies: none","Content-Security-Policy: block-all-mixed-content; frame-src 'self' www.googletagmanager.com ; object-src 'none'; form-action 'self'; font-src 'self' data: fonts.gstatic.com ; connect-src 'self' https://region1.analytics.google.com www.google-analytics.com https://ampcid.google.com https://stats.g.doubleclick.net/j/collect ; script-src 'self' 'unsafe-inline' google-analytics.com https://ssl.google-analytics.com www.google-analytics.com tagmanager.google.com googletagmanager.com www.googletagmanager.com stats.g.doubleclick.net","Referrer-Policy: no-referrer","Clear-Site-Data: '*'","feature-policy: autoplay 'none'; camera 'none';","Permissions-Policy: accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=()","Expect-CT: max-age=0; report-uri='https://baslogicdotcom.report-uri.com/r/d/ct/reportOnly'"],"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
